<template>
    <div v-if="product" class="popup full">
        <div class="popup-container">
            <!-- TopContainer -->
            <div class="top-container p-0">
                <!-- CloseButton -->
                <button @click="$router.push(`/${shop.id}`)" class="popup-close-btn">
                    <img src="@/assets/icons/ic-close-circle-white.svg" />
                </button>
                <!-- ProducImage -->
                <div class="image-container">
                    <img v-if="product.image" :src="product.image" />
                    <div v-else class="flex-element center">
                        <img src="@/assets/icons/ic-image-default.svg" />
                    </div>
                </div>
                <!-- ProductName -->
                <div style="width: calc(100% - 170px)">
                    <h4 class="mb-1">{{ product.name }}</h4>
                    <div v-if="product.description" class="subtitle font-weight-500 mb-1">{{ product.description }}</div>
                    <div class="font-weight-700 font-19 text-dark-warm-grey mb-0">${{ product.price[cart.method] }}</div>
                </div>
            </div>
            <!-- ProductExtraList -->
            <div v-for="(extra, extraIndex) in product.extras" :key="extra.id" class="list-container" :class="renderSelectedClass(extraIndex)">
                <!-- ExtraName -->
                <div class="option-topic">
                    <span class="topic">{{ extra.name }}</span>
                    <span :class="renderSelectedClass(extraIndex)" class="selected">{{ renderSelectedLabel(extraIndex) }}</span>
                </div>
                <!-- ItemList -->
                <div class="option-container" :class="{ 'b-b-0': extraIndex == product.extras.length - 1 }">
                    <span v-for="item in extra.items" :key="item.id" @click="addExtraItem(extraIndex, item)" class="option" :class="{ active: renderActiveClass(extra.id, item.id) }">
                        {{ item.name }}
                        ${{ item.price[cart.method] }}
                    </span>
                </div>
            </div>
            <!-- Count -->
            <div class="counter mb-84">
                <button @click="minusCount" :disabled="count == 1" class="minus">
                    <img v-if="count !== 1" src="@/assets/icons/ic-minus-black.svg" />
                    <img v-else src="@/assets/icons/ic-minus-gray.svg" />
                </button>
                <span class="count">{{ count }}</span>
                <button @click="addCount" :disabled="count == 99" class="plus">
                    <img v-if="count !== 99" src="@/assets/icons/ic-plus-black.svg" />
                    <img v-else src="@/assets/icons/ic-plus-gray.svg" />
                </button>
            </div>
            <!-- SubmitButton -->
            <div class="fixed-bottom-btn">
                <button v-if="product.soldOutAtShopIds.includes(shop.id) || shop.isClosed" class="btn primary-btn" disabled>
                    <span v-if="shop.isClosed">此商店暫時關閉接單</span>
                    <span v-else class="font-weight-700 font-19">餐點已售完</span>
                </button>
                <button v-else class="btn primary-btn" :class="{ disabled: !validateSubmit }" :disabled="!validateSubmit" @click="addToCart">
                    <span class="font-weight-700 font-19">新增 {{ count }} 份至購物車</span>
                    <span class="font-weight-700 font-21">${{ productTotal }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { OrderProduct } from '@/models'
import _ from 'lodash'

export default {
    data() {
        return {
            product: null,
            extras: [],
            count: 1,
        }
    },

    async created() {
        this.product = this.$store.getters.getProduct(this.$route.params.id)

        if (!this.product) return this.$router.push(`/${this.shop.id}`)

        this.extras = _.cloneDeep(this.product.extras).map((extra) => {
            extra.items = _.cloneDeep(extra.items).filter((item) => item.isDefault === true)
            return extra
        })
    },

    computed: {
        ...mapState({
            shop: (state) => state.shop,
            cart: (state) => state.cart,
        }),

        productTotal() {
            const items = _.flatten(_.map(_.cloneDeep(this.extras), (extra) => extra.items))
            let productPrice = this.product.price[this.cart.method]
            let itemsPrice = _.chain(items).map(`price.${this.cart.method}`).sum().value()
            return (productPrice + itemsPrice) * this.count
        },

        validateSubmit() {
            if (this.count < 1) return false
            let ret = true
            this.extras.forEach(extra => {
                if (extra.items.length < extra.minSelected) ret = false
            })
            return ret
        },
    },

    methods: {
        renderSelectedClass(extraIndex) {
            const extra = this.extras[extraIndex]
            if (extra.minSelected > 0 && extra.minSelected > extra.items.length) return ['text-red', 'error']
        },

        renderSelectedLabel(extraIndex) {
            const extra = this.extras[extraIndex]
            if (extra.minSelected > 0 && extra.minSelected > extra.items.length) return '再選 ' + extra.minSelected + ' 項'
            if (extra.maxSelected > 0) return '最多可選 ' + extra.maxSelected + ' 項'
        },

        renderActiveClass(extraId, itemId) {
            let extra = _.find(this.extras, { id: extraId })
            return extra ? _.find(extra.items, { id: itemId }) : false
        },

        addExtraItem(extraIndex, item) {
            console.log(this.extras[extraIndex])
            const extra = this.extras[extraIndex]
            const itemIndex = _.indexOf(_.map(extra.items, 'id'), item.id)
            if (itemIndex > -1) return extra.items.splice(itemIndex, 1)
            if (extra.maxSelected == 1) return extra.items.splice(itemIndex, 1, item)
            if (extra.items.length < extra.maxSelected) return extra.items.push(item)
            return extra.items.splice(itemIndex, 1, item)
        },

        minusCount() {
            if (this.count > 1) this.count--
        },

        addCount() {
            if (this.count < 99) this.count++
        },

        async addToCart() {
            const orderProduct = OrderProduct({ 
                product: this.product,
                items: _.flatten(_.map(_.cloneDeep(this.extras), (extra) => extra.items)),
                count: this.count,
                total: this.productTotal,
            })
            this.$store.dispatch('addCartProduct', { product: orderProduct })
            this.$router.push(`/${this.shop.id}`)
        },
    },
}
</script>
